<nav class="sidebar">
    <ul class="list-unstyled">
        <li><a routerLink='./account-list' routerLinkActive="active">
            <span class="navImage"><img src="../../../assets/images/nav-icon2.png" alt=""></span>
            Accounts
        </a></li>
        <!-- <li><a routerLink='./login' routerLinkActive="active">
            <span class="navImage"><img src="../../../assets/images/nav-icon2.png" alt=""></span>
            Login
        </a></li>
        <li><a routerLink='./register' routerLinkActive="active">
            <span class="navImage"><img src="../../../assets/images/nav-icon2.png" alt=""></span>
            Register
        </a></li> -->
    </ul>
</nav>